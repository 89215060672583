import React, { useContext } from "react";
import Layout from "../../components/Layout";

import CartProducts from "./CartProducts";
import { CartContext } from "../../contexts/CartContext";
import { formatNumber } from "../../helpers/utils";
import { Link } from "react-router-dom";

const Cart = () => {
  const { total, cartItems, itemCount, clearCart, checkout, handleCheckout } =
    useContext(CartContext);

  return (
    <Layout title="Cart" description="This is the Cart page">
      <div>
        <div className="text-center mt-5 tyu">
          <h1>Cart Details</h1>
          <p id="padd">Your Added Items</p>
        </div>

        <div className="row no-gutters justify-content-center">
          <div className="col-sm-9 p-3 t">
            {cartItems.length > 0 ? (
              <CartProducts />
            ) : (
              <div className="p-3 text-center text-muted">
                Your cart is empty
              </div>
            )}

            {checkout && (
              <div className="p-3 text-center text-success">
                <p>Checkout successfull</p>
                <Link to="/" className="btn btn-outline-success btn-sm">
                  Order More
                </Link>
              </div>
            )}
          </div>

          <div className="cleardix"></div>
          {cartItems.length > 0 && (
            <>
              <div className="col-sm-12 p-12">
                <textarea
                  placeholder="Special instructions...?"
                  style={{ width: "100%", borderRadius: "5px", height: "80px" }}
                ></textarea>
              </div>

              <div className="col-sm-9 p-3" style={{ padding: "0px" }}>
                {/* <div className="card card-body"> */}
                <div>
                  <p
                    className="col-sm-3"
                    style={{ width: "40%", float: "left", margin: "0px" }}
                  >
                    Item Total :{" "}
                  </p>
                  <h4
                    className="col-sm-2 txt-right"
                    style={{ paddingTop: "5px", width: "60%", margin: "0px" }}
                  >
                    {itemCount}
                  </h4>

                  <p
                    className="col-sm-3"
                    style={{ width: "40%", float: "left", margin: "0px" }}
                  >
                    Taxes and Charges :{" "}
                  </p>
                  <h4
                    className="col-sm-2 txt-right"
                    style={{ paddingTop: "5px", width: "60%", margin: "0px" }}
                  >
                    {total}
                  </h4>

                  <hr />

                  <p
                    className="col-sm-4"
                    style={{ width: "40%", float: "left", margin: "0px" }}
                  >
                    TO PAY :
                  </p>
                  <h3
                    className="col-sm-3 txt-right"
                    style={{
                      paddingTop: "5px",
                      width: "60%",
                      float: "left",
                      margin: "0px",
                    }}
                  >
                    {formatNumber(total)}
                  </h3>
                  <hr className="my-4" />
                  <hr className="my-4" />
                  <div className="text-center">
                    <br /> <br />
                    {/* <button type="button" className="btn btn-primary mb-2" onClick={handleCheckout} style={{backgroundColor:'#d9534f'}}>CHECKOUT</button> */}
                    <button
                      type="button"
                      className="btn btn-outlineprimary btn-sm"
                      onClick={clearCart}
                    >
                      CLEAR
                    </button>
                  </div>
                </div>
              </div>
            </>
          )}

          <div className="col-sm-12 p-12" id="checkout">
         
          <Link to="/"  style={{
                color: "black",
                fontSize: "20px",
               
                fontSize: "16px",
                paddingTop: "1px",
              }}><i class="fa fa-arrow-circle-left" style={{fontSize:'30px',color:'#d9534f', paddingTop: "7px",float:'left'}}></i>
            <span
              style={{
                color: "black",
                fontSize: "20px",
                float: "left",
                fontSize: "16px",
                paddingTop: "12px",
                paddingLeft: "10px",
              }}
            >
              <i class="fa fa-shopping-cart"></i>
              {itemCount}
            </span>
          
            <h3
              className="col-sm-3 txt-right"
              style={{
                paddingTop: "15px",
                paddingLeft: "10px",
                float: "left",
                margin: "0px",
                fontSize: "16px",
              }}
            >
              {formatNumber(total)}
            </h3>
            </Link>
            <button
              type="button"
              className="btn btn-primary mb-2"
              onClick={clearCart}
              style={{
                backgroundColor: "rgb(76 175 80)",
                width: "50%",
                float: "right",
              }}
            >
              PROCEED TO PAY
            </button>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Cart;
