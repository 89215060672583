import React, { useContext } from "react";
import {
  PlusCircleIcon,
  MinusCircleIcon,
  TrashIcon,
} from "../../components/icons";
import { CartContext } from "../../contexts/CartContext";

import { formatNumber } from "../../helpers/utils";

const CartItem = ({ product }) => {
  const { increase, decrease, removeProduct } = useContext(CartContext);

  return (
    <>
    <div className="">
      {/* <div className="col-sm-2 p-2">
                <img
                alt={product.name}
                style={{margin: "0 auto", maxHeight: "50px"}} 
                src={product.photo} className="img-fluid d-block"/>
            </div> */}
      <div className="col-sm-8 e8" style={{float:'left',width:'85%'}}>
      <img class="menubtn-img" src="veg.20069ac1.svg" alt="Menu" style={{float:'left',paddingTop:'5px'}} />
        
        <h5 className="mb-1" style={{float:'left',paddingLeft:'5px'}}>{product.name}</h5>  &nbsp;   
        <p className="mb-1"  style={{paddingLeft:'15px'}}>₹{product.price} </p>
      </div>
      <div className="col-sm-4 e4"  style={{float:'right',marginRight:'-5px'}}>
          <div style={{float:'left'}}>
            {product.quantity > 1 && (
              <button
                onClick={() => decrease(product)}
                className="btn btn-danger btn-sm mb-1"
              >
                <MinusCircleIcon width={"20px"} />
              </button>
            )}

            {product.quantity === 1 && (
              <button
                onClick={() => removeProduct(product)}
                className="btn btn-danger btn-sm mb-1"
              >
                <TrashIcon width={"20px"} />
              </button>
            )}
            </div>

            <div style={{float:'left',border: '1px solid #d2d1d1',width: '30px',textAlign:'center',height:'33px'}}>
         
         <p className="mb-0">{product.quantity}</p>
       </div>
       <div style={{float:'left'}}>
          <button onClick={() => increase(product)} style={{padding:'6px',borderTopLeftRadius:'0px',borderBottomLeftRadius:'0px',paddingRight:'10px',paddingLeft:'10px'}}>
            <PlusCircleIcon width={"20px"} />
          </button>
        </div>
      </div>
     
    </div>
  <hr className="my-4"/>
  </>
 );
};

export default CartItem;
