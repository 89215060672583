import React from 'react';
import Header from './shared/header';
import Footer from './shared/footer';

import { Helmet } from 'react-helmet-async';

import 'bootswatch/dist/lux/bootstrap.css'

const Layout = ({title, description, children}) => {
    return ( 
        <>
        <Helmet>
            <title>{ title ? title + " - Binix POS" : "Binix POS" }</title>
            <meta name = "description" content={ description || "Binix POS" } />
        </Helmet>
        <Header/>
        <main className="container" style={{maxWidth:'515px'}}>
            {children}
        </main>
        <Footer/>
        </>
     );
}
 
export default Layout;