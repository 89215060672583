import React from "react";
import Layout from "../../components/Layout";

import ProductsGrid from "./ProductsGrid";

const Store = () => {

 
  const closeNav = () => {
    debugger;
    document.getElementById("mySidenav").style.width = "0";
  };
  return (
    <Layout
      title="Store"
      description="This is the Store page"
      style={{ alignSelf: "center" }}
    >
      <div
        className="col-lg-12 col-md-12 col-xs-12"
        style={{ padding: "10px",background:'white' }}
      >
      
      <div id="mySidenav" className="sidenav">
          <a
            href="javascript:void(0)"
            className="closebtn"
            onClick={(e) => closeNav()}
          >
            &times;
          </a>
          <a href="#">About</a>
          <a href="#">Services</a>
          <a href="#">Clients</a>
          <a href="#">Contact</a>
        </div>
      

        <ProductsGrid />
      </div>
      
    </Layout>
  );
};

export default Store;
