import React, { createContext, useState, useEffect} from 'react';
import { dummyProducts } from '../services/dummy';
import * as api from './api.js';

export const ProductsContext = createContext()

const ProductsContextProvider = ({ children }) => {
      
const [products] = useState(dummyProducts);
    
// const [products, setPosts] = useState([]);
//     useEffect(() => {
//         loadPosts();
//         async function loadPosts() {
//             api.getAllMenuItems()
//                 .then((response) => {
//                 const posts = response.data;                                                                                                                                                                                                                            
//                 setPosts(posts);
//                // alert("MenuItems");
//             })
//             .catch(error => {
//                 console.log(error);
//                 setPosts([]);
//             });
//         }

       
//     }, []);
    
 

return (
    <ProductsContext.Provider value={{ products }} >

        { children}
    </ProductsContext.Provider>
);
}

export default ProductsContextProvider;