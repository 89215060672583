import React, { createContext, useState, useEffect} from 'react';
import { dummyCategory } from '../services/dummycategory';
import * as api from './api.js';


export const CategoryContext = createContext()


const CategoryContextProvider = ({children}) => {

    const [category] = useState(dummyCategory);

    // const [category, setPosts] = useState([]);

    // useEffect(() => {

    //     loadPosts();

    //     async function loadPosts() {
    //         api.getAllCategory()
    //             .then((response) => {
    //             const posts = response.data;                                                                                                                                                                                                                            
    //             setPosts(posts);
    //             // alert("Category");
    //         })
    //         .catch(error => {
    //             console.log(error);
    //             setPosts([]);
    //         });
    //     }

       
    // }, []);

    return ( 
        <CategoryContext.Provider value={{category}} >
            { children }
        </CategoryContext.Provider>
     );
}
 
export default CategoryContextProvider;