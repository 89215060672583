import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import MailIcon from "@material-ui/icons/Mail";
import { Link } from 'react-router-dom';

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
});

export default function SwipeableTemporaryDrawer() {
  const classes = useStyles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      style={{ textAlign: "center" }}
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <div >
      <Link to='/' style={{textDecoration:'none'}}><img src="logo.jpg" alt="Menu" /></Link>
     
      <Divider /> </div>
      <span
        className="card-title"
        style={{ width: "15%", fontSize: "14px", fontWeight: "600" }}
      >
        {/* <hr style={{  border:' 1px solid #b7b4b4 !important',padding:'0px',margin:'0px;'}} /> */}
        B I N I X
      </span>
      <Divider />
      <List>
        <ListItem button key='QR Scanner' >
          <ListItemIcon> <InboxIcon /> </ListItemIcon>
          <Link to='/QRscanner' style={{textDecoration:'none'}}>QR Scanner</Link>
          {/* <ListItemText primary='QR Scanner' /> */}
        </ListItem>
        <Divider />
        <ListItem button key='Mobile Login'>
          <ListItemIcon> <InboxIcon /> </ListItemIcon>
          <ListItemText primary='Mobile Login' />
        </ListItem>
        <Divider />
        <ListItem button key='Menu'>
          <ListItemIcon> <InboxIcon /> </ListItemIcon>
          <ListItemText primary='Menu' />
        </ListItem>
        <Divider />
        <ListItem button key='About Us'>
          <ListItemIcon> <InboxIcon /> </ListItemIcon>
          <ListItemText primary='About Us' />
        </ListItem>
      </List>
      <Divider />
    </div>
  );

  return (
    <div>
      {["left"].map((anchor) => (
        <React.Fragment key={anchor}>
          <Button
            onClick={toggleDrawer(anchor, true)}
            style={{ float: "left", textAlign: "left" }}
          >
            <span style={{ fontSize: "30px", cursor: "pointer" }}>&#9776;</span>
            &nbsp; POS Binix
            {/* {anchor} */}
          </Button>
          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
          >
            {list(anchor)}
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </div>
  );
}
