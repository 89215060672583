import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { CartContext } from "../../contexts/CartContext";
import { CartIcon } from "../icons";
import styles from "./header.module.scss";
import SwipeableDrawer from './swipeabledrawer'



const Header = () => {
  const { itemCount } = useContext(CartContext);

  const openNav = () => {
    debugger;
    document.getElementById("mySidenav").style.width = "250px";
  };

  

  return (
    <header className={styles.header} id="Header">
     

     <SwipeableDrawer></SwipeableDrawer>


      {/* <Link to="/">Menu</Link> */}
      {/* <Link to='/about'>About</Link> */}
      {/* <Link to="/cart">
        {" "}
        <CartIcon /> Cart ({itemCount})
      </Link> */}
      
      {/* <span
          style={{ fontSize: "30px", cursor: "pointer" }}
          onClick={(e) => openNav()}
        >
          &#9776;
        </span>&nbsp;
 POS Binix */}
 
        
    </header>
  );
};

export default Header;
